import axios from "axios";
import Cookies from "universal-cookie";
import toast from "react-hot-toast";
import { BLAUTHTOKEN, LOGINTOKEN, deleteDataFromStore, getDataFromStore } from "../utils/CookiesUtils";
// import { UNAUTHORIZED } from "../api/constants";

const updateURL = (url) => {
    if (url.indexOf("##HTTPS##") >= 0) {
        url = url.replace("##HTTPS##", window.location.protocol.substring(0, window.location.protocol.indexOf(":")));
    }
    if (url.indexOf("##HOST##") >= 0) {
        url = url.replace("##HOST##", window.location.hostname);
    }
    if (url.indexOf("##PORT##") >= 0) {
        url = url.replace("##PORT##", window.location.port);
    }
    if (url.indexOf("##CID##") >= 0) {
        url = url.replace("##CID##", getDataFromStore("cid"));
    }

    return url;
}

const getHeaders = (headerMap) => {
    const loginToken = getDataFromStore(BLAUTHTOKEN);
    if (!loginToken) {
        headerMap["Authorization"] = loginToken;
        return headerMap;
    }
    headerMap["Authorization"] = loginToken;
    return headerMap;
};

const handleSuccess = (result, successCallBack) => {
    if (successCallBack != null) {
        successCallBack(result.data);
    }
};

const handleFailure = (error, failedCallBack) => {
    // toast.error(error?.response?.data?.message ? error?.response?.data?.message : error.message);
    if (failedCallBack != null) {
        if (error.response?.data?.code === 401) {
            deleteDataFromStore(LOGINTOKEN)
            window.location.href = "/login";
        }
        failedCallBack(error);
    }
};

export const callPostApiWithoutAuth = (url, data, successCallBack, failedCallBack) => {
    url = updateURL(url);
    axios
        .post(url, data)
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};

export const callGetApi = (url, headerMap, successCallBack, failedCallBack) => {
    url = updateURL(url);
    axios
        .get(url, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};

export const callGetApiWithoutAuth = (url,successCallBack,failedCallBack) =>{
    url = updateURL(url);
    axios
    .get(url)
    .then((result) => handleSuccess(result,successCallBack))
    .catch((error) => handleFailure(error,failedCallBack));
}
export const callPostApi = (url, data, headerMap, successCallBack, failedCallBack) => {
    url = updateURL(url);
    axios
        .post(url, data, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};

export const callPutApi = (url, data, headerMap, successCallBack, failedCallBack) => {
    url = updateURL(url);
    axios
        .put(url, data, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};

export const callPatchApi = (url, data, headerMap, successCallBack, failedCallBack) => {
    url = updateURL(url);
    axios
        .patch(url, data, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};

export const callDeleteApi = (url, headerMap, successCallBack, failedCallBack) => {
    url = updateURL(url);
    axios
        .delete(url, {
            headers: getHeaders(headerMap),
        })
        .then((result) => handleSuccess(result, successCallBack))
        .catch((error) => handleFailure(error, failedCallBack));
};
