import React, { useState } from "react";
import PrivacyPolicy from "../common/PrivacyPolicy";

const LoginWithId = ({ setCurrentLoginScreen }) => {
    const [termsModalOpen, setTermsModalOpen] = useState(false);
    const [privacyModalOpen, setPrivacyModalOpen] = useState(false);

    return (
        <>
            <div className="col-lg-6 col-9 text-center input-login position-relative">
                <div className="d-flex align-items-center justify-content-center">
                    <div className="d-block">
                        <div className=" position-relative">
                            <img src="assets/img/shadow.png" alt="shadow" className="img-fluid round logo-shadow" />
                            <div className="logo-position">
                                <img src="assets/img/logo.png" alt="100% secure" className="img-fluid" />
                            </div>
                        </div>
                        <button className="border-0 yellow-gradient-btn" onClick={() => setCurrentLoginScreen("loginScreen")}>
                            <span className="yellow-inner-box" style={{ padding: "13px 86px" }}>
                                <span className="text-content">Login with User ID</span>
                            </span>
                        </button>
                    </div>
                </div>
                <p className="text-grey fs-12 mt-2 mb-0">
                    NATIONAL INDIAN GAMING COMMISSION Regulatory Compliance <br />
                    <span className="text-yellow mx-1">★</span> Gaming Integrity
                </p>
                <p className="m-0 mt-1 fs-12 text-grey">
                    By Login you accept you are 18+ and agree to our
                    <span className="text-yellow mx-1" onClick={() => setTermsModalOpen(true)}>
                        T&amp;C
                    </span>{" "}
                    and
                    <span className="text-yellow mx-1" onClick={() => setPrivacyModalOpen(true)}>
                        Privacy Policy
                    </span>
                </p>
                {termsModalOpen && <PrivacyPolicy showModal={true} toggleModel={setTermsModalOpen} modalType="terms" />}
                {privacyModalOpen && <PrivacyPolicy showModal={true} toggleModel={setPrivacyModalOpen} modalType="privacy" />}
                <div>
                    <img src="assets/img/casinoText.png" alt="casinoText" className="w-50 casinoText" />
                </div>
            </div>
        </>
    );
};

export default LoginWithId;
