import React from "react";
import ModalBox from "../common/Modal";
import {useState } from "react";
import {callPostApi } from "../../api/ApiCaller";
import {Change_Password_POST } from "../../api/ApiConst";
import toast from "react-hot-toast";
import {useNavigate } from "react-router-dom";
import {isEmpty } from "../../utils/TextUtils";
import PrivacyPolicy from "../common/PrivacyPolicy";
import { BLAUTHTOKEN, deleteDataFromStore, setDataInStore } from "../../utils/CookiesUtils";

const defaultLoginObj = {
    newPassword: "",
    confirmPassword: "",
    masterPassword: "",
};

const LoginPasswordUpdate = ({ showModal, toggleModel, loginToken }) => {
    const navigate = useNavigate();
    const[record, setRecord] = useState(defaultLoginObj);
    const [termsModalOpen, setTermsModalOpen] = useState(false);
    const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
    const [newPassword, setNewPassword] = useState(false);
    const [showConfirmPassword, setshowConfirmPassword] = useState(false);
    const [masterPassword, setMasterPassword] = useState(false);

    const handleChangePassword = () => {
        if (isEmpty(record.newPassword)){
            toast.error("New password must not be empty.");
            return;
        }
        if (isEmpty(record.confirmPassword)){
            toast.error("Confirm password must not be empty.");
            return;
        }
        if (record.newPassword.length < 8){
            toast.error("New password should contain at least 8 characters.",{ id: "Toast" });
            return;
        }
        if (record.confirmPassword.length < 8){
            toast.error("Confirm password should contain at least 8 characters.",{ id: "Toast" });
            return;
        }
        if (record.newPassword !== record.confirmPassword){
            toast.error("New password and confirm password do not match.",{ id: "Toast" });
            return;
        }
        if (isEmpty(record.masterPassword)){
            toast.error("Master password must not be empty.",{ id: "Toast" });
            return;
        }
        const payload = {
            newPassword: record.newPassword,
            confirmPassword: record.confirmPassword,
            masterPassword: record.masterPassword,
        };
        setDataInStore(BLAUTHTOKEN, loginToken);
        callPostApi(
            Change_Password_POST,
            payload,
            {},
            (response) => {
                if (response.code === 200) {
                    setRecord(defaultLoginObj);
                    navigate("/");
                    toast.success(response.message, { id: "Toast" });
                    toggleModel();
                } else {
                    deleteDataFromStore(BLAUTHTOKEN);
                    toast.error(response.message);
                }
            },
            (e) => {
                if (e.response.data.success === false) {
                    if (e.response.data.code === 400) {
                        toast.error("Please provide a valid password");
                    }
                    if (e.response.data.code === 422) {
                        toast.error(e.response.data.message);
                    }
                } else {
                    toast.error(e.message);
                }
            }
        );
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setRecord((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <ModalBox show={showModal} toggleModel={toggleModel} modalClass="login-modal">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content border-content">
                    <div className="modal-header border-gradient position-relative">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            CHANGE PASSWORD
                        </h5>
                        <span className="btn-close cursor-pointer" onClick={toggleModel} />
                        <div className="header-shadow">
                            <img src="assets/img/shadow-header.png" alt="shadow-header" className="img-fluid" style={{ width: "73%" }} />
                        </div>
                    </div>
                    <div className="modal-body text-center">
                        <div className="login-box input-login w-100">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <img src="assets/img/password.png" alt="login-img" className="img-fluid" style={{ width: 20 }} />
                                    </div>
                                </div>
                                <input
                                    type={newPassword ? "text" : "password"}
                                    className="form-control"
                                    id="inlineFormInputGroup"
                                    placeholder="New Password"
                                    name="newPassword"
                                    value={record.newPassword}
                                    onChange={(e) => handlePasswordChange(e)}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text" onClick={() => setNewPassword(!newPassword)} style={{ cursor: "pointer" }}>
                                        {newPassword ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" style={{ fill: "#ffdc60", transform: "", msfilter: "" }}>
                                                <path d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 11c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z" />
                                                <path d="M12 10c-1.084 0-2 .916-2 2s.916 2 2 2 2-.916 2-2-.916-2-2-2z" />
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" style={{ fill: "#ffdc60", transform: "", msfilter: "" }}>
                                                <path d="M8.073 12.194 4.212 8.333c-1.52 1.657-2.096 3.317-2.106 3.351L2 12l.105.316C2.127 12.383 4.421 19 12.054 19c.929 0 1.775-.102 2.552-.273l-2.746-2.746a3.987 3.987 0 0 1-3.787-3.787zM12.054 5c-1.855 0-3.375.404-4.642.998L3.707 2.293 2.293 3.707l18 18 1.414-1.414-3.298-3.298c2.638-1.953 3.579-4.637 3.593-4.679l.105-.316-.105-.316C21.98 11.617 19.687 5 12.054 5zm1.906 7.546c.187-.677.028-1.439-.492-1.96s-1.283-.679-1.96-.492L10 8.586A3.955 3.955 0 0 1 12.054 8c2.206 0 4 1.794 4 4a3.94 3.94 0 0 1-.587 2.053l-1.507-1.507z" />
                                            </svg>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <img src="assets/img/password.png" alt="login-img" className="img-fluid" style={{ width: 20 }} />
                                    </div>
                                </div>
                                <input
                                    type={showConfirmPassword ? "text" : "Password"}
                                    className="form-control"
                                    id="inlineFormInputGroup"
                                    placeholder="Confirm Password"
                                    name="confirmPassword"
                                    value={record.confirmPassword}
                                    onChange={(e) => handlePasswordChange(e)}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text" onClick={() => setshowConfirmPassword(!showConfirmPassword)} style={{ cursor: "pointer" }}>
                                        {showConfirmPassword ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" style={{ fill: "#ffdc60", transform: "", msfilter: "" }}>
                                                <path d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 11c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z" />
                                                <path d="M12 10c-1.084 0-2 .916-2 2s.916 2 2 2 2-.916 2-2-.916-2-2-2z" />
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" style={{ fill: "#ffdc60", transform: "", msfilter: "" }}>
                                                <path d="M8.073 12.194 4.212 8.333c-1.52 1.657-2.096 3.317-2.106 3.351L2 12l.105.316C2.127 12.383 4.421 19 12.054 19c.929 0 1.775-.102 2.552-.273l-2.746-2.746a3.987 3.987 0 0 1-3.787-3.787zM12.054 5c-1.855 0-3.375.404-4.642.998L3.707 2.293 2.293 3.707l18 18 1.414-1.414-3.298-3.298c2.638-1.953 3.579-4.637 3.593-4.679l.105-.316-.105-.316C21.98 11.617 19.687 5 12.054 5zm1.906 7.546c.187-.677.028-1.439-.492-1.96s-1.283-.679-1.96-.492L10 8.586A3.955 3.955 0 0 1 12.054 8c2.206 0 4 1.794 4 4a3.94 3.94 0 0 1-.587 2.053l-1.507-1.507z" />
                                            </svg>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <div className="input-group-text">
                                        <img src="assets/img/password.png" alt="login-img" className="img-fluid" style={{ width: 20 }} />
                                    </div>
                                </div>
                                <input
                                    type={masterPassword ? "text" : "Password"}
                                    className="form-control"
                                    id="inlineFormInputGroup"
                                    placeholder="Master Password"
                                    name="masterPassword"
                                    value={record.masterPassword}
                                    onChange={(e) => handlePasswordChange(e)}
                                />
                                <div className="input-group-append">
                                    <div className="input-group-text" onClick={() => setMasterPassword(!masterPassword)} style={{ cursor: "pointer" }}>
                                        {masterPassword ? (
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" style={{ fill: "#ffdc60", transform: "", msfilter: "" }}>
                                                <path d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 11c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z" />
                                                <path d="M12 10c-1.084 0-2 .916-2 2s.916 2 2 2 2-.916 2-2-.916-2-2-2z" />
                                            </svg>
                                        ) : (
                                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" style={{ fill: "#ffdc60", transform: "", msfilter: "" }}>
                                                <path d="M8.073 12.194 4.212 8.333c-1.52 1.657-2.096 3.317-2.106 3.351L2 12l.105.316C2.127 12.383 4.421 19 12.054 19c.929 0 1.775-.102 2.552-.273l-2.746-2.746a3.987 3.987 0 0 1-3.787-3.787zM12.054 5c-1.855 0-3.375.404-4.642.998L3.707 2.293 2.293 3.707l18 18 1.414-1.414-3.298-3.298c2.638-1.953 3.579-4.637 3.593-4.679l.105-.316-.105-.316C21.98 11.617 19.687 5 12.054 5zm1.906 7.546c.187-.677.028-1.439-.492-1.96s-1.283-.679-1.96-.492L10 8.586A3.955 3.955 0 0 1 12.054 8c2.206 0 4 1.794 4 4a3.94 3.94 0 0 1-.587 2.053l-1.507-1.507z" />
                                            </svg>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <button className="green-gradient-btn cursor-pointer" onClick={() => handleChangePassword()}>
                                <span className="green-inner-box" style={{ padding: "11px 48px" }} onClick={() => handleChangePassword()}>
                                    <span className="text-content">OK</span>
                                </span>
                            </button>
                            <p className="m-0 mt-1 fs-12 text-grey mt-4">
                                By Login you accept you are 18+ and agree to our
                                <span className="text-yellow privacyPolicy" onClick={() => setTermsModalOpen(true)}>
                                    T&amp;C
                                </span>{" "}
                                and
                                <span className="text-yellow privacyPolicy" onClick={() => setPrivacyModalOpen(true)}>
                                    Privacy Policy
                                </span>
                            </p>
                            <PrivacyPolicy showModal={termsModalOpen} toggleModel={setTermsModalOpen} modalType="terms" />
                            <PrivacyPolicy showModal={privacyModalOpen} toggleModel={setPrivacyModalOpen} modalType="privacy" />
                        </div>
                        <div className="logo-opacity">
                            <div className="logo-shadow position-relative">
                                <img src="assets/img/shadow.png" alt="100% secure" className="img-fluid round" style={{ width: 160 }} />
                                <div className="logo-position">
                                    <img src="assets/img/logo.png" alt="100% secure" className="img-fluid" style={{ width: 100 }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ModalBox>
    );
};

export default LoginPasswordUpdate;
