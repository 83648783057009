import React, { useEffect, useState } from 'react'
import { callGetApiWithoutAuth } from '../../api/ApiCaller';
import { Privacy_Policy_GET } from '../../api/ApiConst';
import ModalBox from './Modal';
import toast from 'react-hot-toast';


const PrivacyPolicy = ({ showModal, toggleModel, modalType }) => {
    const [privacyPolicy, setPrivacyPolicy] = useState([]);
    const [termsAndConditions, setTermsAndConditions] = useState([]);

    useEffect(() => {
        handlePrivacyModal();
    }, [])
    
    const handlePrivacyModal = () => {
        callGetApiWithoutAuth(Privacy_Policy_GET, (response) => {
            if (response.code === 200) {
                let res = response.data;
                setPrivacyPolicy(res.privacy);
                setTermsAndConditions(res.term);
              
            } else {
                toast.error(response.message);
            }
        },
        (e) => {
            toast.error(e.message)
        })
    }

    const isTermsModal = modalType === 'terms';

    return (
        <ModalBox show={showModal} toggleModel={toggleModel} modalClass="login-modal">
            <div className="modal-dialog modal-dialog-centered modal-lg">
                <div className="modal-content border-content">
                    <div className="modal-header border-gradient position-relative">
                        <h5 className="modal-title" id="staticBackdropLabel">
                            {isTermsModal ? 'Terms & Conditions' : 'Privacy Policy'}
                        </h5>
                        <span className="btn-close privacyPolicy" onClick={(e) => { e.stopPropagation(); toggleModel(); }}  />
                    </div>
                    <div className="modal-body text-center">
                        {isTermsModal ? (
                            <div dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
                        ) : (
                            <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
                        )}
                    </div>
                </div>
            </div>
        </ModalBox>
    )
}
export default PrivacyPolicy