import React, { useEffect, useState } from "react";
import Login from "./Login";
import LoginWithId from "./LoginWithId";
import LoginPasswordUpdate from "./LoginPasswordUpdate";
import { callGetApiWithoutAuth } from "../../api/ApiCaller";
import { ONLINE_USERS } from "../../api/ApiConst";
import toast from "react-hot-toast";

const LoginUser = () => {
    const [currentLoginScreen, setCurrentLoginScreen] = useState("loginWithId");
    const [onlineUsers, setOnlineUsers] = useState(0)
    useEffect(() => {
        callGetApiWithoutAuth(ONLINE_USERS, (response) => {
            if (response.code === 200) {
                setOnlineUsers(response.data);
            } else {
                console.error(response);
            }
        })
    }, [])

    return (
        <>
            <div className="header">
                <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                        <img src="assets/img/secure.png" alt="100% secure" className="img-fluid me-3 secure-img" />
                        <div className="safe-payment d-flex align-items-center">
                            <div className="hundred">100%</div>
                            <div className="sp">SAFE PAYMENT</div>
                        </div>
                    </div>
                    {/* <div className="heading-postn">
                        <h3 className="fancy text-white mb-0">
                            <span>Log In</span>
                        </h3>
                    </div> */}
                    <div className="d-flex align-items-center justify-content-end">
                        <img src="assets/img/online-player.png" alt="Safe Payment" className="img-fluid me-2 op-w" />
                        <p className="text-white m-0 fs-14">Online Players: {onlineUsers}</p>
                    </div>
                </div>
            </div>
            <div className="main-content">
                <div className="container-fluid">
                    <div className="row align-items-center justify-content-center w-100 ">
                        <div className="col-lg-6 col-3 text-center">
                            <img src="assets/img/login-img.png" alt="100% secure" className="img-fluid bannerImg" />
                        </div>
                        {currentLoginScreen === "loginWithId" && <LoginWithId setCurrentLoginScreen={setCurrentLoginScreen} />}
                        {currentLoginScreen === "loginScreen" && <Login setCurrentLoginScreen={setCurrentLoginScreen} />}
                        {/* {currentLoginScreen === "loginWithPassword" && <LoginPasswordUpdate />} */}
                    </div>
                </div>
            </div>
            <div className="customer-service-div">
                <a href="#" className="customer-service-btn-outer">
                    <span className="customer-service-btn-inner d-flex align-items-center">
                        <span>
                            <img src="assets/img/customer-service.png" alt="customer-service" className="img-fluid cs-img" />
                        </span>
                        <p className="p-1 pe-2 m-0 text-white fs-14 pe-3">Customer Service</p>
                    </span>
                </a>
            </div>
        </>
    );
};

export default LoginUser;
