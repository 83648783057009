import React, { useEffect, useState } from "react";
import Header from "./common/Header";
import Footer from "./common/Footer";
import { Gift_Item_GET, Profile_GET } from "../api/ApiConst";
import toast, { Toaster } from "react-hot-toast";
import { callGetApi } from "../api/ApiCaller";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
    const [profile, setProfile] = useState([]);
    const [giftItem, setGiftItem] = useState([]);
    const [showBell, setShowBell] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getProfileDetails();
        getGiftDetails();
    }, []);

    const getProfileDetails = () => {
        callGetApi(
            Profile_GET,
            {},
            (response) => {
                if (response.code === 200) {
                    let res = response.data;
                    setProfile(res);
                } else {
                    toast.error(response.message);
                }
            },
            (e) => {
                toast.error(e.message);
            }
        );
    };

    const getGiftDetails = () => {
        callGetApi(
            Gift_Item_GET,
            {},
            (response) => {
                if (response.code === 200) {
                    let res = response.data;
                    res.reverse();
                    setGiftItem(res);
                    const seenGiftItemCount = localStorage.getItem('seenGiftItemCount') || 0;
                    if (res.length > seenGiftItemCount) {
                        setShowBell(true);
                    } else {
                        setShowBell(false);
                    }
                } else {
                    toast.error(response.message);
                }
            },
            (e) => {
                toast.error(e.message);
            }
        );
    }
    
    const handleJoinTable = (game) => {
        navigate("/joinTable", { state: { game: game } });
    };

    return (
        <>
            <Header profile={profile} getProfileDetails={getProfileDetails} getGiftDetails={getGiftDetails} giftItem={giftItem} showBell={showBell} setShowBell={setShowBell}/>
            <div className="main-content">
                <div className="container-fluid ">
                    <div className="d-flex align-items-center justify-content-center ">
                        <div className="col-3 px-4 text-center ">
                            <div className="h-100 teenpatti-box position-relative" onClick={() => handleJoinTable("teenPatti")}>
                                <img src="assets/img/teenpati-framee.png" alt="teenpatti" className="img-fluid main-frame-width" />

                                <div className="shine-heading">
                                    <div className="shine overflow-hidden position-relative">
                                        <img src="assets/img/heading-teenpatti.png" alt="teenpatti" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="teenpatti-inner">
                                    <img src="assets/img/teenpatti-girl.png" alt="teenpatti" className="img-fluid tg-width" />
                                </div>
                                <div className="teenpatti-cards">
                                    <div className="card-teenpatti card-left me-2">
                                        <div className="front">
                                            <img src="assets/img/card-back.png" alt="teenpatti" className="img-fluid w-100" />
                                        </div>
                                        <div className="back">
                                            <img src="assets/img/black-heart.png" alt="teenpatti" className="img-fluid w-100" />
                                        </div>
                                    </div>
                                    <div className="card-teenpatti card-middle me-2">
                                        <div className="front">
                                            <img src="assets/img/card-back.png" alt="teenpatti" className="img-fluid w-100" />
                                        </div>
                                        <div className="back">
                                            <img src="assets/img/heart.png" alt="teenpatti" className="img-fluid w-100" />
                                        </div>
                                    </div>
                                    <div className="card-teenpatti card-right">
                                        <div className="front">
                                            <img src="assets/img/card-back.png" alt="teenpatti" className="img-fluid w-100" />
                                        </div>
                                        <div className="back">
                                            <img src="assets/img/spade.png" alt="teenpatti" className="img-fluid w-100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3 px-4 text-center">
                            <div className="h-100 teenpatti-box position-relative" onClick={() => handleJoinTable("andarBahar")}>
                                <img src="assets/img/frame.png" alt="teenpatti" className="img-fluid main-frame-width" />
                                <div className="shine-heading-bottom">
                                    <div className="shine overflow-hidden position-relative">
                                        <img src="assets/img/heading-andarbahar.png" alt="teenpatti" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="ab-card-left">
                                    <img src="assets/img/black-htd.png" alt="teenpatti" className="img-fluid" />
                                </div>
                                <div className="ab-card-right">
                                    <img src="assets/img/heart-td.png" alt="teenpatti" className="img-fluid" />
                                </div>
                                <div className="bh-icon">
                                    <img src="assets/img/spade-td.png" alt="teenpatti" className="img-fluid" style={{ width: "2.5vw" }} />
                                </div>
                                <div className="club-icon">
                                    <img src="assets/img/club.png" alt="teenpatti" className="img-fluid" style={{ width: "3vw" }} />
                                </div>
                                <div className="diamond-icon">
                                    <img src="assets/img/diamond.png" alt="teenpatti" className="img-fluid" style={{ width: "2vw" }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-3 px-4 text-center">
                            <div className="h-100 teenpatti-box position-relative" onClick={() => handleJoinTable("updown")}>
                                <img src="assets/img/frame.png" alt="teenpatti" className="img-fluid main-frame-width" />
                                <div className="shine-heading-bottom">
                                    <div className="shine overflow-hidden position-relative">
                                        <img src="assets/img/down-up.png" alt="teenpatti" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="king-card">
                                    <img src="assets/img/king.png" alt="teenpatti" className="img-fluid" />
                                </div>
                                <div className="queen-card">
                                    <img src="assets/img/queen.png" alt="queen" className="img-fluid" />
                                </div>
                                <div className="bh-icon">
                                    <img src="assets/img/spade-td.png" alt="spade" className="img-fluid" style={{ width: "2vw" }} />
                                </div>
                                <div className="diamond-icon">
                                    <img src="assets/img/diamond.png" alt="diamond" className="img-fluid" style={{ width: "3vw" }} />
                                </div>
                                <div className="static-chip">
                                    <img src="assets/img/chips3.png" alt="chips" className="img-fluid" />
                                </div>
                                <div className="red-chip">
                                    <img src="assets/img/chips-1.png" alt="chips" className="img-fluid" />
                                </div>
                                <div className="black-chip">
                                    <img src="assets/img/chips-2.png" alt="chips" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="col-3 px-4 text-center">
                            <div className="h-100 teenpatti-box position-relative" onClick={() => handleJoinTable("dragon")}>
                                <img src="assets/img/frame.png" alt="teenpatti" className="img-fluid main-frame-width" />
                                <div className="shine-heading-bottom">
                                    <div className="shine z-1 overflow-hidden position-relative">
                                        <img src="assets/img/dragom-tiger-head.png" alt="teenpatti" className="img-fluid" />
                                    </div>
                                </div>
                                <div className="tiger-card">
                                    <img src="assets/img/tiger-card.png" alt="teenpatti" className="img-fluid" style={{ width: "9vw" }} />
                                    <img src="assets/img/tiger.png" alt="tiger" className="tiger-face img-fluid" />
                                </div>
                                <div className="dragon-card">
                                    <img src="assets/img/dragon-card.png" alt="teenpatti" className="img-fluid" style={{ width: "9vw" }} />
                                </div>
                                <img src="assets/img/fire.gif" alt="fire" className="fire-gif img-fluid"/>
                                <img src="assets/img/dragon.png" alt="tiger" className="dragon-face img-fluid"/>
                                <div className="trophy">
                                    <img src="assets/img/trophy.png" alt="trophy" className="img-fluid" style={{ width: "5vw" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer profile={profile} getProfileDetails={getProfileDetails} />
        </>
    );
};

export default Dashboard;
