
const HTTPS = process.env.REACT_APP_HTTPS;
const HOST = process.env.REACT_APP_HOST;
const PORT = process.env.REACT_APP_PORT;
const SERVICE = process.env.REACT_APP_SERVICE;

export const BASE_URL = `${HTTPS}://${HOST}:${PORT}` + (SERVICE !== "" ? "/" + SERVICE : "");
export const BASE_AUTH_URL_V1 = BASE_URL + "/customer/auth/v1";
export const BASE_URL_V1 = BASE_URL + "/customer/account/v1";
// const recordID = "{recordID}";
// const activateURL = "activate/" + recordID;
// const inActivateURL = "inactivate/" + recordID;
// const publish = "publish/" + recordID;
// const unpublish = "unPublish/" + recordID;
// const deleteURL = "delete/" + recordID;
// const reviveURL = "revive/" + recordID;
// const deletedListURL = "deleted-list";
// const listURL = "list";
// const updateUrl = "update/";
const login = "/login"

// Login
export const User_Login_Post = BASE_AUTH_URL_V1 + login;
export const ONLINE_USERS = BASE_URL + "/public/v1/online-players";

// change password
export const Change_Password_POST = BASE_URL_V1 + "/change-password";

//profile
export const Profile_Avatar_List_GET = BASE_URL_V1 + "/avatars";
export const Profile_GET = BASE_URL_V1 + "/Profile";
export const Update_Profile_Avatar_POST = BASE_URL_V1 + "/update-avatar";
export const Update_Profile_Theme = BASE_URL_V1 + "/update-theme";
export const GET_TRANSACTION_DETAILS = BASE_URL_V1 + "/transactions?page={page}";

//settings
export const Privacy_Policy_GET = BASE_URL + "/public/v1/settings";

//Gift-item
export const Gift_Item_GET = BASE_URL_V1 + "/git-item";





