import React, { useEffect, useState } from "react";
import ModalBox from "./Modal";
import { useNavigate } from "react-router-dom";
import { BLAUTHTOKEN, deleteDataFromStore } from "../../utils/CookiesUtils";
import ConfirmationDialoge from "./confirmdialog";
import LoginPasswordUpdate from "../login/LoginPasswordUpdate";
import { callGetApi, callGetApiWithoutAuth, callPostApi } from "../../api/ApiCaller";
import { GET_TRANSACTION_DETAILS, Privacy_Policy_GET, Profile_Avatar_List_GET, Update_Profile_Avatar_POST } from "../../api/ApiConst";
import toast, { Toaster } from "react-hot-toast";
import Loader from "./loader";
import { convertAmount } from "../../utils/CurrencyUtils";
import dateUtilities from "../../utils/DateUtilities";

const Header = ({ profile, getProfileDetails, getGiftDetails, giftItem, setShowBell, showBell }) => {
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showProfileEditModal, setShowProfileEditModal] = useState(false);
    const [ShowHowToPlayModal, setShowHowToPlayModal] = useState(false);
    const [ShowTermsAndConditionsModal, setShowTermsAndConditionsModal] = useState(false);
    const [ShowTransactionHistoryModal, setShowTransactionHistoryModal] = useState(false);
    const [ShowPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        isAuth: true,
        title: "",
        subTitle: "",
        severity: "",
    });
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [privacyPolicy, setPrivacyPolicy] = useState([]);
    const [termsAndConditions, setTermsAndConditions] = useState([]);
    const [index, setIndex] = useState(1);
    const [transactionList, setTransactionList] = useState([]);
    const [transactionListDetails, setTransactionListDetails] = useState(null);
    const [expandedItem, setExpandedItem] = useState(null);
    const [avatarList, setAvatarList] = useState([]);
    const [selectedAvatar, setSelectedAvatar] = useState(profile?.profilePic);

    const navigate = useNavigate();

    useEffect(() => {
        setLoading(false);
    }, [profile]);

    useEffect(() => {
        callGetApi(
            Profile_Avatar_List_GET,
            {},
            (response) => {
                if (response.code === 200) {
                    let res = response.data;
                    setAvatarList(res);
                } else {
                    toast.error(response.message);
                }
            },
            (e) => {
                toast.error(e.message);
            }
        );
        handlePrivacyModal();
        getTransactionDetails();
    }, []);

    const closeModal = () => {
        setShowModal(false);
    };

    const handleLogout = () => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
        });
        deleteDataFromStore(BLAUTHTOKEN);
        navigate("/");
    };

    const handleAvatar = (avatar) => {
        setSelectedAvatar(avatar.image);
        setLoading(true);
        const payload = {
            avatarId: avatar._id,
        };
        callPostApi(
            Update_Profile_Avatar_POST,
            payload,
            {},
            (response) => {
                if (response.code === 200) {
                    getProfileDetails();
                }
            },
            (e) => {
                setLoading(false);
                toast.error(e.message);
            }
        );
    };

    const getTransactionDetails = () => {
        callGetApi(
            GET_TRANSACTION_DETAILS.replaceAll("{page}", index),
            {},
            (response) => {
                if (response.code === 200) {
                    const res = response.data;
                    if (index === 1) {
                        setTransactionList(res.transactionList);
                    } else {
                        const _transactionList = [...transactionList, ...res.transactionList];
                        setTransactionList(_transactionList);
                    }
                    setIndex(index + 1);
                    setTransactionListDetails({
                        recordsFiltered: res.pagination.totalItems,
                        recordsTotal: res.pagination.totalItems,
                        total: res.pagination.totalItems,
                    });
                }
            },
            (error) => {}
        );
    };

    const handlePrivacyModal = () => {
        callGetApiWithoutAuth(
            Privacy_Policy_GET,
            (response) => {
                if (response.code === 200) {
                    let res = response.data;
                    setPrivacyPolicy(res.privacy);
                    setTermsAndConditions(res.term);
                } else {
                    toast.error(response.message);
                }
            },
            (e) => {
                toast.error(e.message);
            }
        );
    };

    const markGiftsAsSeen = (e) => {
        localStorage.setItem("seenGiftItemCount", giftItem.length);
        setShowBell(false);
    };

    return (
        <>
            <div className="header position-relative">
                <div className="d-flex align-items-center justify-content-end">
                    <div className="header-left-outer">
                        <div className="d-flex align-items-center">
                            <div className="d-flex align-items-center me-4 cursor-pointer" onClick={() => setShowProfileModal(true)}>
                                <div className="profile-box me-3">
                                    <img src={profile?.profilePic} alt="user" className="img-fluid" />
                                </div>
                                <div className="d-block">
                                    <p className="text-white m-0 fs-14">{profile?.userName}</p>
                                    {/* <p className="text-yellow m-0 fs-10">{profile._id}</p> */}
                                </div>
                            </div>
                            {profile?.accountBalance && (
                                <div className="total-amount">
                                    <img src="assets/img/coin.png" alt="amount" className="img-fluid coin" />
                                    {/* {convertAmount(profile?.accountBalance)} */}
                                    {profile?.accountBalance?.toFixed(2)}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <div className="">
                            <button className="giftMenu p-0 " type="button" id="dropdownMicroProcessorTrigger" data-bs-toggle="dropdown" aria-expanded="false" onClick={markGiftsAsSeen}>
                                <span className=" position-relative d-flex justify-content-center me-2 " onClick={getGiftDetails}>
                                    <img src="assets/img/tressure.png" alt="Tressure" className="img-fluid tressure-width" />
                                    {showBell && <span className="circleSideShowEffects"></span>}
                                    {giftItem.length > 0 && (
                                        <>
                                            <span className="bell">
                                                <img src="assets/img/bell.png" alt="bell" />
                                            </span>
                                        </>
                                    )}
                                </span>
                            </button>
                            <div className="dropdown-menu text-start customDropDownMenu gift-notify" aria-labelledby="dropdownMicroProcessorTrigger">
                                {giftItem.length > 0 ? (
                                    giftItem.map((item, index) => (
                                        <div className="py-2" key={index} style={{ borderBottom: "1px solid #fff" }}>
                                            <div className="text-muted" style={{ fontSize: "0.8rem" }}>
                                                <span className="customText me-2">{item.giftTittle}:</span>
                                                {expandedItem === item ? (
                                                    <>
                                                        {item.giftDescription}
                                                        <span
                                                            className="show-less text-white"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                setExpandedItem(null);
                                                            }}
                                                        >
                                                            Show less
                                                        </span>
                                                    </>
                                                ) : (
                                                    <>
                                                        {item.giftDescription.length > 100 ? `${item.giftDescription.substring(0, 100)}...` : item.giftDescription}
                                                        {item.giftDescription.length > 100 && (
                                                            <span
                                                                className="show-more text-white"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setExpandedItem(item);
                                                                }}
                                                            >
                                                                Show more
                                                            </span>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="d-flex justify-content-center align-items-center" style={{ height: "100px" }}>
                                        <div className="text-white" style={{ fontSize: "0.8rem" }}>
                                            There are no notifications.
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="">
                            <button className="arrowMenu  me-2 menu-outer" type="button" id="dropdownMicroProcessorTrigger" data-bs-toggle="dropdown" aria-expanded="false">
                                <img src="assets/img/menu.png" alt="menu" />
                            </button>
                            <div className="dropdown-menu customDropDownMenu" aria-labelledby="dropdownMicroProcessorTrigger">
                                <div>
                                    <span className="d-flex align-items-center text-start p-1 mb-2 cursor-pointer" onClick={() => setShowHowToPlayModal(true)}>
                                        <span className="me-2">
                                            <img src="assets/img/exitIcon.png" alt="exitIcon" width={"28px"} />
                                        </span>
                                        <span className="customText">How To Play</span>
                                    </span>
                                    <span className="d-flex align-items-center text-start p-1 mb-2 cursor-pointer" onClick={() => setShowTermsAndConditionsModal(true)}>
                                        <span className="me-2">
                                            <img src="assets/img/exitIcon.png" alt="exitIcon" width="28px" />
                                        </span>
                                        <span className="customText">Terms & Conditions</span>
                                    </span>
                                    <span className="d-flex align-items-center text-start p-1 mb-2 cursor-pointer" onClick={() => setShowTransactionHistoryModal(true)}>
                                        <span className="me-2">
                                            <img src="assets/img/exitIcon.png" alt="exitIcon" width="28px" />
                                        </span>
                                        <span className="customText">Transaction History</span>
                                    </span>
                                    <span className="d-flex align-items-center text-start p-1 mb-2 cursor-pointer" onClick={() => setShowPrivacyPolicyModal(true)}>
                                        <span className="me-2">
                                            <img src="assets/img/exitIcon.png" alt="exitIcon" width="28px" />
                                        </span>
                                        <span className="customText">Privacy Policy</span>
                                    </span>
                                </div>
                                {/* <div className="dropdown-item">
                            Leave Seat
                        </div>
                        <div className="dropdown-item">
                            Leave Table
                        </div> */}
                            </div>
                            {/* <span className="headerPlayer">Round:1/20</span> */}
                        </div>
                    </div>
                </div>
            </div>

            <ModalBox show={showProfileModal} toggleModel={() => setShowProfileModal(false)} modalClass={"sliderBlockModal"}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-content">
                        <div className="modal-header border-gradient position-relative">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                PROFILE
                            </h5>
                            <span className="btn-close cursor-pointer" onClick={() => setShowProfileModal(false)} />
                            <div className="header-shadow">
                                <img src="assets/img/shadow-header.png" alt="shadow-header" className="img-fluid" style={{ width: "73%" }} />
                            </div>
                        </div>
                        <div className="modal-body text-center">
                            <div className="user-details">
                                <div className="profile-box-lg mx-auto">
                                    <img src={profile.profilePic} alt="user" className="img-fluid rounded-50" />
                                    <div className="edit-profile cursor-pointer" onClick={() => setShowProfileEditModal(true)}>
                                        <img src="assets/img/edit-profile.png" alt="user" className="img-fluid" />
                                    </div>
                                </div>
                                <h6 style={{ color: "#616161" }} className="fs-14 m-0 fw-600 mt-2">
                                    {profile.userName}
                                </h6>
                                {/* <p className="text-brown fs-12 fw-600 m-0">{profile?._id}</p> */}
                            </div>
                            <div className="user-details mt-2">
                                {/* <div className="d-flex align-items-center justify-content-between">
                                    <p className="text-brown fs-12 fw-600 m-0">Deposited</p>
                                    <p className="text-brown fs-12 fw-600 m-0">₹30</p>
                                </div> */}
                                {/* <div className="d-flex align-items-center justify-content-between mt-2">
                                    <p className="text-brown fs-12 fw-600 m-0">Winnings</p>
                                    <p className="text-brown fs-12 fw-600 m-0">₹0</p>
                                </div> */}
                                <div className="d-flex align-items-center justify-content-between mt-2">
                                    <p className="text-brown fs-14 fw-600 m-0">Total Cash</p>
                                    <p className="text-brown fs-14 fw-600 m-0">{profile?.accountBalance % 1 === 0 ? profile?.accountBalance : profile?.accountBalance?.toFixed(2)}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                    <button className="w-100 border-0 golden-gradient-btn mt-3 cursor-pointer" onClick={(e) => setShowModal(true)}>
                                        <span className="golden-inner-box">
                                            <button className="text-content border-0 fs-11">
                                                <img src="assets/img/logout.png" alt="user" className="img-fluid mr-2 me-2" style={{ width: 13 }} />
                                                CHANGE PASSWORD
                                            </button>
                                        </span>
                                    </button>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                    <button
                                        className="w-100 border-0 golden-gradient-btn mt-3 cursor-pointer"
                                        onClick={() =>
                                            setConfirmDialog({
                                                isOpen: true,
                                                title: "Are You Sure? You want to logout",
                                                onConfirm: () => {
                                                    handleLogout();
                                                },
                                            })
                                        }
                                    >
                                        <span className="golden-inner-box" style={{ padding: "10px 30px" }}>
                                            <span className="text-content">
                                                <img src="assets/img/logout.png" alt="user" className="img-fluid mr-2" style={{ width: 13 }} />
                                                LOGOUT
                                            </span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ModalBox show={showProfileEditModal} toggleModel={() => setShowProfileEditModal(false)} modalClass={"sliderBlockModal"}>
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content border-content">
                        <div className="modal-header border-gradient position-relative">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                CHOOSE AVATAR
                            </h5>
                            <span className="btn-close cursor-pointer" onClick={() => setShowProfileEditModal(false)} />
                            <div className="header-shadow">
                                <img src="assets/img/shadow-header.png" alt="shadow-header" className="img-fluid" style={{ width: "73%" }} />
                            </div>
                        </div>
                        <div className="modal-body text-center">
                            <div className="row">
                                <div className="col-4">
                                    <div className="avtaar-box">
                                        <div className="avtaar-box-inner d-flex align-items-center justify-content-center">
                                            <div className="d-block position-relative">
                                                <div className="profile-box-lg mx-auto">
                                                    {loading && <Loader />}
                                                    {!loading && <img src={profile?.profilePic} alt="user" className="img-fluid rounded-50" />}
                                                    {/* <div className="edit-profile">
                                                        <img
                                                            src="assets/img/camera.png"
                                                            alt="user"
                                                            className="img-fluid "
                                                        />
                                                    </div> */}
                                                </div>
                                                {/* <button className="cursor-pointer mt-4 w-100 green-gradient-btn">
                                                    <span className="green-inner-box" style={{ padding: "6px 11px" }}>
                                                        <span className="text-content" style={{ fontSize: "13px" }}>RECEIVE</span>
                                                    </span>
                                                </button> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-8">
                                    <div className="avtaar-box">
                                        <div className="avtaar-box-inner">
                                            <ul id="avtaarselect" className="position-relative z-2 w-100 d-flex align-items-center justify-content-center flex-wrap list-unstyled p-0 m-0">
                                                {avatarList.map((avatar, index) => (
                                                    <li key={index} className={`cursor-pointer avtaar ${selectedAvatar?.image === avatar.image ? "" : ""}`} onClick={() => handleAvatar(avatar)}>
                                                        <img src={avatar.image} alt="user" className="img-fluid rounded-50" />
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ModalBox show={ShowHowToPlayModal} toggleModel={() => setShowHowToPlayModal(false)} modalClass={"sliderBlockModal"}>
                <div className="modal-dialog modal-dialog-centered modal-lg cmsPageDialog">
                    <div className="modal-content border-content">
                        <div className="modal-header border-gradient position-relative">
                            <h5 className="modal-title cmsPage" id="staticBackdropLabel">
                                HOW TO PLAY
                            </h5>
                            <span className="btn-close cursor-pointer" onClick={() => setShowHowToPlayModal(false)} />
                            <div className="header-shadow">
                                <img src="assets/img/shadow-header.png" alt="shadow-header" className="img-fluid" style={{ width: "73%" }} />
                            </div>
                        </div>
                        <div className="modal-body text-center">
                            <ul id="myTabs" className="nav nav-pills nav-justified customTabs" role="tablist" data-tabs="tabs">
                                <li>
                                    <a className="active" href="#TeenPatti " data-toggle="tab">
                                        Teen Patti
                                    </a>
                                </li>
                                <li>
                                    <a href="#AndarBahar" data-toggle="tab">
                                        Andar Bahar
                                    </a>
                                </li>
                                <li>
                                    <a href="#7UpDown" data-toggle="tab">
                                        7 Up Down
                                    </a>
                                </li>
                                <li>
                                    <a href="#DragonTiger" data-toggle="tab">
                                        Dragon Tiger
                                    </a>
                                </li>
                            </ul>
                            <div className="tab-content text-start mt-2 ">
                                <div role="tabpanel" className="tab-pane fade active show" id="TeenPatti">
                                    <h6 className="text-white">Teen Patti Rules</h6>
                                    <p className="text-white">
                                        When learning how to play teen patti, one of the first steps is learning the teen patti rules for hand rankings. There are six teen patti sequence in the game.
                                        Three of a Kind, also known as Trail or Trio, is the highest hand and High Card is the lowest hand in the 3 patti hand rankings chart. The 3 patti hand rankings
                                        are listed below in a high-to-low order:
                                    </p>
                                    <h6 className="text-white">Trail/Three of a Kind/Trio </h6>
                                    <p className="text-white">
                                        A Trail, Trio, or three-of-a-kind hand consists of three cards of the same rank. For instance, three aces A♥️A♠️A♣️ is the highest-ranking three-of-a-kind
                                        combination and triple twos 2♣️ 2♦️ 2♥️ are the lowest-ranking trail combination.
                                    </p>
                                    <h6 className="text-white">Pure Sequence/Straight Run</h6>
                                    <p className="text-white">
                                        A Pure Sequence or a Striaght Run consists of three consecutive cards of the same suit. For instance, A♦️ 2♦️ 3♦️is the highest-ranking pure sequence, followed
                                        by A♦️ K♦️Q♦️ and K♦️ Q♦️ J♦️. The lowest pure sequence in 3 patti is 4♦️ 3♦️ 2♦️. Also, 2♦️ A♦️ K♦️ doesn’t form a valid pure sequence.
                                    </p>
                                    <h6 className="text-white">Sequence/Normal Run</h6>
                                    <p className="text-white">
                                        Three consecutive cards, not all of the same suit, form a normal run. The highest-ranking normal run is A♠️2♥️3♠️ followed by A♥️K♣️Q♠️, K♦️Q♠️J♥️, and so on
                                        till 4♠️3♥️2♦️.
                                    </p>
                                    <h6 className="text-white">Color/Flush</h6>
                                    <p className="text-white">
                                        The Flush or Color hand consists of three cards of the same suit, with no sequential order. For instance, A♣️ Q♣️ J♣️ can form a color or flush. If two players
                                        make a flush hand, the highest card is compared. If the highest card rank is the same in both hands, the second highest cards are compared. If the
                                        second-highest card rank is also the same, the lowest card ranks are compared. The highest-ranking flush hand is A-K-J, and the lowest is 5-3-2.
                                    </p>
                                    <h6 className="text-white">Pair</h6>
                                    <p className="text-white">
                                        A Pair consists of two cards of the same rank and a third kicker card. For instance, A-A-K or J-J-9 form a pair. The highest-ranking pair is an A-A-K and the
                                        lowest-ranking pair is 2-2-3.
                                    </p>
                                    <h6 className="text-white">High Card or No Pair</h6>
                                    <p className="text-white">
                                        A High Card or Pair consists of three cards that do not make any of the combinations. The highest-ranking high card hand is A-K-J of mixed suits, and the
                                        lowest-ranking hand is 5-3-2.
                                    </p>
                                    <h5 className="text-white">Teen Patti Rules for Gameplay</h5>
                                    <h6 className="text-white">Objective</h6>
                                    <p className="text-white">
                                        The object of the 3 patti card game is to make the highest-ranking three-card hand combination and win the pot. As the game progresses, the players attempt to
                                        maximize the pot to secure the highest winnings. The player with the best hand combination at the end of the game wins the pot.
                                    </p>
                                    <h6 className="text-white">3 Patti Rules for Shuffling and Dealing</h6>
                                    <p className="text-white">
                                        The dealer shuffles a standard 52-card deck (without jokers) before dealing. Before the dealer deals cards, each player places the predetermined boot amount
                                        into the pot. The dealer then deals three cards, to each player, in a clock-wise direction. The cards are dealt face-down, so no player can see their cards
                                        initially.
                                    </p>
                                    <h6 className="text-white">Betting Procedure Rules</h6>
                                    <p className="text-white">
                                        The game is played clockwise, starting from the player at the left of the dealer. The players place their bets according to the strength of their hand. Each
                                        player can either place a bet without seeing their cards (playing blind), or after seeing their cards (playing seen). Players also have the option to fold and
                                        place no bet. If a player chooses to fold, their contribution to the pot is forfeited.
                                    </p>
                                    <h6 className="text-white">Rules for Blind Player</h6>
                                    <p className="text-white">
                                        As per the rules of Teen Patti, if a player chooses to play blind, they cannot look at their cards before placing a bet. The bet of a blind player must be equal
                                        to but not more than twice the total amount in the pot. If the first player is a blind player, their bet must be equal to the boot or more.
                                    </p>
                                    <p className="text-white">
                                        The amount that the blind player places in the pot becomes the stake amount. The next player must either match the stake amount or exceed it while placing their
                                        bet. However, seen players must bet double the stake amount.
                                    </p>
                                    <p className="text-white">
                                        A blind player can also ask for a show, after which the hands of the two players are compared, and the winner collects the pot. However, there are a few
                                        conditions to this:
                                    </p>
                                    <h6 className="text-white">Only two players should be remaining in the game.</h6>
                                    <ul style={{ paddingLeft: "20px" }}>
                                        <li className="fs-12 text-white">
                                            A blind player must pay for the show an amount equal to the stake, whether or not the other player is a seen player or blind player.
                                        </li>
                                        <li className="fs-12 text-white">Seen players cannot ask for a show. If you are a seen player, you can either place your bet or fold.</li>
                                        <li className="fs-12 text-white">
                                            If both players are seen players, and either of them asks for a show, the player that asked for a show must place twice the amount of the current stake in
                                            the pot.
                                        </li>
                                        <li className="fs-12 text-white">If the hands of both players are equal in the show, the player who asked for the show loses the hand.</li>
                                    </ul>
                                    <h6 className="text-white">Rules for Seen Player</h6>
                                    <p className="text-white">
                                        According to the teen patti rules for seen players, a seen player can play chaal, show, sideshow, or fold. As a seen player, if you want to stay in the game
                                        after viewing your cards, you must play chaal.
                                    </p>
                                    <p className="text-white">
                                        When a seen player plays chaal, they place their bet, which must be between two to four times the current stake (or the boot if they are the first player). If
                                        the previous player was a blind player, the bet becomes the stake amount. If the previous player was a seen player, half their bet becomes the stake amount.
                                    </p>
                                    <p className="text-white">
                                        A seen player can request a show as per the rules for show mentioned above. A seen player can also ask for a sideshow to another player. As per the teen patti
                                        rules for sideshow:
                                    </p>

                                    <ul style={{ paddingLeft: "20px" }}>
                                        <li className="fs-12 text-white">
                                            A seen player can compare their cards with the last players (provided there is one or more players remaining in the game and the previous player was a seen
                                            player).
                                        </li>
                                        <li className="fs-12 text-white">The player requesting a sideshow must place twice the amount of the current stake in the pot.</li>
                                        <li className="fs-12 text-white">The previous player can accept ot decline a sideshow.</li>
                                        <li className="fs-12 text-white">If the previous player declines a sideshow, no cards are compared, and the round countinues.</li>
                                        <li className="fs-12 text-white">
                                            If the previous player accepts a sideshow, the cards of both players are compared, and the previous player must fold if the player who requested a sideshow
                                            has a higher-ranking hand.
                                        </li>
                                        <li className="fs-12 text-white">If the previous player has a higher-ranking hand, the player who requested the sideshow must fold.</li>
                                    </ul>
                                </div>
                                <div role="tabpanel" className="tab-pane fade" id="AndarBahar">
                                    <h6 className="text-white">Game Objective</h6>
                                    <p className="text-white">
                                        Andar Bahar is a casino game where you bet on whether the next card drawn will be from the "inside" (Andar) or "outside" (Bahar) pile. The game objective is to
                                        win by correctly predicting the pile on which the next drawn card will land.
                                    </p>
                                    <h6 className="text-white">Dealer’s Role</h6>
                                    <p className="text-white">
                                        The dealer, also known as the house, in the Andar Bahar game is responsible for shuffling the card deck, dealing the cards, and collecting & paying out bets.
                                        They also ensure that the game is fair and that all players follow the rules.
                                    </p>
                                    <h6 className="text-white">Rules</h6>
                                    <p className="text-white">
                                        Although the Andar Bahar game is a luck-based card game, its simple rules and the same aspect make it enjoyable and worth playing on a fun casino game night.
                                        With that said, let us look at the simple and easy-to-understand rules which make the game thrilling.
                                    </p>
                                    <ul style={{ paddingLeft: "20px" }}>
                                        <li className="fs-12 text-white">The gameplay takes place with a single deck of cards.</li>
                                        <li className="fs-12 text-white">The dealer deals a single card face up, known as the Joker.</li>
                                        <li className="fs-12 text-white">Players then predict whether the next card with the same rank will land on the "Andar" or "Bahar" side.</li>
                                        <li className="fs-12 text-white">The player wins their bet if the next card is from the same section as the Joker.</li>
                                        <li className="fs-12 text-white">The player loses their bet if the next card is from the opposite section.</li>
                                        <li className="fs-12 text-white">The game continues until a player wins or until the player runs out of money.</li>
                                    </ul>
                                </div>
                                <div role="tabpanel" className="tab-pane fade" id="7UpDown">
                                    <h6 className="text-white">7 Up And 7 Down?</h6>
                                    <p className="text-white">
                                        Setting the bet, placing the bet, and open the card are your only actions in 7 up down cash game. Online gambling is a simple way to make money.
                                    </p>
                                    <ul style={{ paddingLeft: "20px" }}>
                                        <li className="fs-12 text-white">At the outset, pick the total bet amount you're comfortable with from the available points.</li>
                                        <li className="fs-12 text-white">
                                            You must select one of the options: 7Up, 7Down. After a player is picked, the card will begin to open. If the total falls within the parameters of your
                                            chosen option, you get to keep the money.
                                        </li>
                                        <li className="fs-12 text-white">These 7 up 7 down card game rules are the only ones that matter.</li>
                                    </ul>
                                </div>
                                <div role="tabpanel" className="tab-pane fade" id="DragonTiger">
                                    <h6 className="text-white">Dragon v/s Tiger?</h6>
                                    <ul style={{ paddingLeft: "20px" }}>
                                        <li className="fs-12 text-white">First, you bet on either the Dragon or the Tiger box by placing chips on either of the two options.</li>
                                        <li className="fs-12 text-white">Then the dealer draws one card for each set and places them on the respective boxes on the table.</li>
                                        <li className="fs-12 text-white">You win if your initial bet or call has the highest card value among the two box options. Or else, you lose. </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ModalBox show={ShowTermsAndConditionsModal} toggleModel={() => setShowTermsAndConditionsModal(false)} modalClass={"sliderBlockModal"}>
                <div className="modal-dialog modal-dialog-centered modal-lg cmsPageDialog">
                    <div className="modal-content border-content">
                        <div className="modal-header border-gradient position-relative">
                            <h5 className="modal-title cmsPage" id="staticBackdropLabel">
                                TERMS & CONDITIONS
                            </h5>
                            <span className="btn-close cursor-pointer" onClick={() => setShowTermsAndConditionsModal(false)} />
                            <div className="header-shadow">
                                <img src="assets/img/shadow-header.png" alt="shadow-header" className="img-fluid" style={{ width: "73%" }} />
                            </div>
                        </div>
                        <div className="modal-body text-start text-white">
                            <div dangerouslySetInnerHTML={{ __html: termsAndConditions }} />
                            <div>
                                <b>Note</b> : We are not responsible if your game faces any problem due to network.
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ModalBox show={ShowTransactionHistoryModal} toggleModel={() => setShowTransactionHistoryModal(false)} modalClass={"sliderBlockModal"}>
                <div className="modal-dialog modal-dialog-centered modal-lg cmsPageDialog">
                    <div className="modal-content border-content">
                        <div className="modal-header border-gradient position-relative">
                            <h5 className="modal-title cmsPage" id="staticBackdropLabel">
                                Transaction History
                            </h5>
                            <span className="btn-close cursor-pointer" onClick={() => setShowTransactionHistoryModal(false)} />
                            <div className="header-shadow">
                                <img src="assets/img/shadow-header.png" alt="shadow-header" className="img-fluid" style={{ width: "73%" }} />
                            </div>
                        </div>
                        <div className="modal-body text-center">
                            <div className="table-responsive">
                                <table className="table table-bordered table-hover mb-3 ">
                                    <thead className="thead-dark">
                                        <tr>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Transaction Type</th>
                                            <th scope="col">Remark</th>
                                            <th scope="col">Main Balance</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>
                                    {transactionList && transactionList.length > 0 ? (
                                        <tbody>
                                            {transactionList?.map((d, i) => (
                                                <tr key={d._id}>
                                                    <th scope="row">{d.amount.toFixed(2)}</th>
                                                    <td>{d.transactionType}</td>
                                                    <td>{d.remark}</td>
                                                    <td>{d.accountBalance.toFixed(2)}</td>
                                                    <td>{dateUtilities.formattedDate(d.createdAt, "dd-MM-yyyy, hh:mm aa")}</td>
                                                </tr>
                                            ))}
                                            {transactionListDetails && transactionListDetails?.recordsTotal > transactionList.length && (
                                                <tr>
                                                    <td colSpan={5}>
                                                        <button className="green-gradient-btn cursor-pointer" onClick={() => getTransactionDetails()}>
                                                            <span className="green-inner-box" style={{ padding: "11px 48px" }} onClick={() => getTransactionDetails()}>
                                                                <span className="text-content">Load More</span>
                                                            </span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    ) : (
                                        <div className="my-3 text-black text-center">No Data Available!</div>
                                    )}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ModalBox show={ShowPrivacyPolicyModal} toggleModel={() => setShowPrivacyPolicyModal(false)} modalClass={"sliderBlockModal"}>
                <div className="modal-dialog modal-dialog-centered modal-lg cmsPageDialog">
                    <div className="modal-content border-content">
                        <div className="modal-header border-gradient position-relative">
                            <h5 className="modal-title cmsPage" id="staticBackdropLabel">
                                Privacy Policy
                            </h5>
                            <span className="btn-close cursor-pointer" onClick={() => setShowPrivacyPolicyModal(false)} />
                            <div className="header-shadow">
                                <img src="assets/img/shadow-header.png" alt="shadow-header" className="img-fluid" style={{ width: "73%" }} />
                            </div>
                        </div>
                        <div className="modal-body text-start text-white">
                            <div dangerouslySetInnerHTML={{ __html: privacyPolicy }} />
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ConfirmationDialoge confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
            <LoginPasswordUpdate showModal={showModal} toggleModel={closeModal} />
        </>
    );
};

export default Header;
