import Cookies from "universal-cookie";

// export const CID = "cid";
export const UserPass = "up";
export const BLAUTHTOKEN = "blAuthToken";
export const LOGINTOKEN = "loginToken";


export const setDataInStore = (key, value) => {
    if (key === null || key === undefined || key === 'undefined') return;
    if (value === null || value === undefined || value === 'undefined') return;
    const cookies = new Cookies();
    var expireDate = new Date();
    expireDate.setDate(expireDate.getDate() + 30);
    cookies.set(key, value, { path: "/", expires: expireDate });
    localStorage.setItem(key, JSON.stringify(value));
    return value;
};

export const getDataFromStore = (key) => {
    const cookies = new Cookies();
    if (cookies.get(key) !== null && cookies.get(key) !== undefined) {
        return cookies.get(key);
    }
    var value = localStorage.getItem(key);
    if (value !== null && value !== undefined && value !== "undefined") {
        return JSON.parse(value);
    }
    return null;
};

export const deleteDataFromStore = (key) => {
    const cookies = new Cookies();
    cookies.remove(key);
    localStorage.removeItem(key);
};

export const clearDataFromStore = () => {
    const cookies = new Cookies();
    cookies.remove();
    localStorage.clear();
};