import { Component } from 'react';
import { format } from 'date-fns';

class DateUtilities extends Component {
    formattedDate = (timeStamp, resultFormat) => {
        if (timeStamp) {
            return format(new Date(timeStamp), resultFormat)
        }
        return
    }
}

const dateUtilities = new DateUtilities()

export default dateUtilities;