import React, { createContext, useEffect, useState } from "react";
import ProgressBar from "./ProgressBar";

const SplashScreen = () => {
    const [value, setValue] = useState(100);
    const [duration, setDuration] = useState(9000);

  return (
    <>
      <div className="main-content">
        <div className="container-fluid text-center">
          <div className=" position-relative">
            <img
              src="assets/img/shadow.png"
              alt="shadow"
              className="img-fluid round logo-shadow-splash"
            />
            <div className="logo-position">
              <img
                src="assets/img/logo.png"
                alt="100% secure"
                className="img-fluid logo-img-splash"
              />
            </div>
          </div>
          <img
            src="assets/img/casino-logo.png"
            alt="100% secure"
            className="img-fluid casino-logo-splash"
          />
          <div>
            <ProgressBar value={value} duration={duration} />
            {/* <div className="controls">
              <label>
                Value:
                <input
                  type="number"
                  value={value}
                  onChange={(e) => setValue(Number(e.target.value))}
                />
              </label>
              <label>
                Duration (ms):
                <input
                  type="number"
                  value={duration}
                  onChange={(e) => setDuration(Number(e.target.value))}
                />
              </label>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SplashScreen;
