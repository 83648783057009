import React, { useState, useEffect } from "react";
import ModalBox from "./Modal";
import { Update_Profile_Theme } from "../../api/ApiConst";
import toast from "react-hot-toast";
import { callPostApi } from "../../api/ApiCaller";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, Scrollbar } from "swiper/modules";
import { Link } from "react-router-dom";
const Footer = ({ profile, getProfileDetails }) => {
    const [showSettingModal, setShowSettingModal] = useState(false);
    const [showChangeCardModal, setShowChangeCardModal] = useState(false);
    const [selectedCard, setSelectedCard] = useState(profile.cardTheme);
    useEffect(() => {
        if (profile.cardTheme) {
            setSelectedCard(profile.cardTheme);
        }
    }, [profile.cardTheme]);

    const handleCard = () => {
        const payload = {
            cardTheme: selectedCard,
        };
        callPostApi(
            Update_Profile_Theme,
            payload,
            {},
            (response) => {
                if (response.code === 200) {
                    getProfileDetails();
                    toast.success(response.message);
                    setShowChangeCardModal(false);
                }
            },
            (e) => {
                toast.error(e.message);
            }
        );
    };

    return (
        <>
            <footer>
                <div className="container-fluid py-1 position-relative">
                    <div className="">
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="text-center me-3 cursor-pointer" onClick={() => setShowChangeCardModal(true)}>
                                <img className="img-fluid changeCard" src="assets/img/changeCard.png" alt="changeCard" />
                            </div>
                            {/* <div className="text-center me-3 cursor-pointer" onClick={() => setShowSettingModal(true)}>
                                <img src="assets/img/settings.png" alt="Settings" className="img-fluid footer-menu" />
                                <p className="yellow-white fs-14 mt-1 mb-0">Setting</p>
                            </div>
                            <div className="text-center me-3 cursor-pointer">
                                <img src="assets/img/union.png" alt="Settings" className="img-fluid footer-menu" />
                                <p className="yellow-white fs-14 mt-1 mb-0">Activity</p>
                            </div>
                            <div className="text-center cursor-pointer">
                                <img src="assets/img/social.png" alt="Settings" className="img-fluid footer-menu" />
                                <p className="yellow-white fs-14 mt-1 mb-0">Service</p>
                            </div> */}
                        </div>
                    </div>
                    <Link to="https://gamaexchange.com/home" className="telegram-btn">
                        <img src="assets/img/cricket.png" alt="whatsapp" className="img-fluid" />
                    </Link>
                    <div className="footer-logo">
                        <img src="assets/img/footer-logo.png" alt="logo" className="img-fluid position-relative z-1 bhagya-logo" />
                        {/* <img src="assets/img/logo-shadow.png" alt="logo" className="img-fluid logo-postn z-0" /> */}
                    </div>
                </div>
            </footer>
            <ModalBox show={showSettingModal} toggleModel={() => setShowSettingModal(false)} modalClass={"sliderBlockModal"}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-content">
                        <div className="modal-header border-gradient position-relative">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                Tip
                            </h5>
                            <span className="btn-close cursor-pointer" onClick={() => setShowSettingModal(false)} />
                            <div className="header-shadow">
                                <img src="assets/img/shadow-header.png" alt="shadow-header" className="img-fluid" style={{ width: "73%" }} />
                            </div>
                        </div>
                        <div className="modal-body text-center">
                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10 col-sm-11">
                                    <div>
                                        <img src="assets/img/logo.png" alt="shadow-header" className="img-fluid" style={{ width: 60 }} />
                                    </div>
                                    <p className="text-white mt-3 yellow-white fs-20">Do you want to quit the game?</p>
                                    <div className="d-flex mx-md-3 mx-sm-2 mx-0 mt-4 align-items-center justify-content-between">
                                        <button className="blue-gradient-btn w-auto border-0 " onClick={() => setShowSettingModal(false)}>
                                            <span className="blue-inner-box" style={{ padding: "8px 30px" }}>
                                                <span className="text-content">CANCEL</span>
                                            </span>
                                        </button>
                                        <button className="blue-gradient-btn pxy-custom w-auto border-0 ">
                                            <span className="blue-inner-box">
                                                <span className="text-content">OKAY</span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
            <ModalBox show={showChangeCardModal} toggleModel={() => setShowChangeCardModal(false)} modalClass={"sliderBlockModal"}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content border-content">
                        <div className="modal-header border-gradient position-relative">
                            <h5 className="modal-title" id="staticBackdropLabel">
                                SELECT CARD
                            </h5>
                            <span className="btn-close cursor-pointer" onClick={() => setShowChangeCardModal(false)} />
                            <div className="header-shadow">
                                <img src="assets/img/shadow-header.png" alt="shadow-header" className="img-fluid" style={{ width: "73%" }} />
                            </div>
                        </div>
                        <div className="modal-body text-center">
                            <Swiper
                                spaceBetween={20}
                                modules={[Navigation, Scrollbar]}
                                navigation={{
                                    nextEl: ".swiper-next-button",
                                    prevEl: ".swiper-prev-button",
                                }}
                                // pagination={{clickable: true}}
                                slidesPerView={4}
                                loop={true}
                                freeMode={true}
                                className="changeCardList p-3 "
                            >
                                <SwiperSlide>
                                    <input type="radio" name="cardTheme" id="cb1" value="yellow" onChange={(e) => setSelectedCard(e.target.value)} checked={selectedCard === "yellow"} />
                                    <label htmlFor="cb1">
                                        <img src="assets/img/cards/yellow.png" alt="yellow" />
                                    </label>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <input type="radio" name="cardTheme" id="cb2" value="red" onChange={(e) => setSelectedCard(e.target.value)} checked={selectedCard === "red"} />
                                    <label htmlFor="cb2">
                                        <img src="assets/img/cards/red.png" alt="red" />
                                    </label>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <input type="radio" name="cardTheme" id="cb3" value="blue" onChange={(e) => setSelectedCard(e.target.value)} checked={selectedCard === "blue"} />
                                    <label htmlFor="cb3">
                                        <img src="assets/img/cards/blue.png" alt="blue" />
                                    </label>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <input type="radio" name="cardTheme" id="cb4" value="green" onChange={(e) => setSelectedCard(e.target.value)} checked={selectedCard === "green"} />
                                    <label htmlFor="cb4">
                                        <img src="assets/img/cards/green.png" alt="green" />
                                    </label>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <input type="radio" name="cardTheme" id="cb5" value="purple" onChange={(e) => setSelectedCard(e.target.value)} checked={selectedCard === "purple"} />
                                    <label htmlFor="cb5">
                                        <img src="assets/img/cards/purple.png" alt="purple" />
                                    </label>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <input type="radio" name="cardTheme" id="cb6" value="pink" onChange={(e) => setSelectedCard(e.target.value)} checked={selectedCard === "pink"} />
                                    <label htmlFor="cb6">
                                        <img src="assets/img/cards/pink.png" alt="pink" />
                                    </label>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <input type="radio" name="cardTheme" id="cb7" value="blackDark" onChange={(e) => setSelectedCard(e.target.value)} checked={selectedCard === "blackDark"} />
                                    <label htmlFor="cb7">
                                        <img src="assets/img/cards/blackDark.png" alt="blackDark" />
                                    </label>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <input type="radio" name="cardTheme" id="cb8" value="greenDark" onChange={(e) => setSelectedCard(e.target.value)} checked={selectedCard === "greenDark"} />
                                    <label htmlFor="cb8">
                                        <img src="assets/img/cards/greenDark.png" alt="greenDark" />
                                    </label>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <input type="radio" name="cardTheme" id="cb9" value="purpleDark" onChange={(e) => setSelectedCard(e.target.value)} checked={selectedCard === "purpleDark"} />
                                    <label htmlFor="cb9">
                                        <img src="assets/img/cards/purpleDark.png" alt="purpleDark" />
                                    </label>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <input type="radio" name="cardTheme" id="cb10" value="yellowDark" onChange={(e) => setSelectedCard(e.target.value)} checked={selectedCard === "yellowDark"} />
                                    <label htmlFor="cb10">
                                        <img src="assets/img/cards/yellowDark.png" alt="yellowDark" />
                                    </label>
                                </SwiperSlide>
                            </Swiper>
                            <div>
                                <div className="swiper-next-button">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" style={{ fill: "rgba(0, 0, 0, 1)", transform: "", msfilter: "" }}>
                                        <path d="M10.061 19.061 17.121 12l-7.06-7.061-2.122 2.122L12.879 12l-4.94 4.939z" />
                                    </svg>
                                </div>
                                <div className="swiper-prev-button">
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" style={{ fill: "rgba(0, 0, 0, 1)", transform: "", msfilter: "" }}>
                                        <path d="M13.939 4.939 6.879 12l7.06 7.061 2.122-2.122L11.121 12l4.94-4.939z" />
                                    </svg>
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-lg-9 col-md-10 col-sm-11">
                                    <div className="d-flex mx-md-3 mx-sm-2 mx-0 align-items-center justify-content-center">
                                        <button className="blue-gradient-btn pxy-custom w-auto border-0 " onClick={handleCard}>
                                            <span className="blue-inner-box">
                                                <span className="text-content">OKAY</span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
        </>
    );
};

export default Footer;
