import React, { useState } from "react";
import { callPostApiWithoutAuth } from "../../api/ApiCaller";
import { User_Login_Post } from "../../api/ApiConst";
import { BLAUTHTOKEN, getDataFromStore, setDataInStore, UserPass } from "../../utils/CookiesUtils";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import PrivacyPolicy from "../common/PrivacyPolicy";
import LoginPasswordUpdate from "./LoginPasswordUpdate";

const Login = () => {
    const defaultLoginObj = {
        username: "",
        password: "",
    };
    const token1 = getDataFromStore(BLAUTHTOKEN);
    const _token = token1 ? token1 : "";
    const [showLoginModal, setShowLoginModal] = useState("flag");
    const [loginToken, setLoginToken] = useState(_token);
    const [loginDetails, setLoginDetails] = useState(defaultLoginObj);
    const [privacyPolicy, setPrivacyPolicy] = useState([]);
    const [termsModalOpen, setTermsModalOpen] = useState(false);
    const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const urlParams = new URLSearchParams(window.location.search);

    const handleLogin = (e) => {
        e.preventDefault();
        if (loginDetails.username === null || loginDetails.username.length === 0) {
            toast.error("Please provide username!", { id: "loginData" });
            return;
        }
        if (loginDetails.password === null || loginDetails.password.length === 0) {
            toast.error("Please provide password!", { id: "loginData" });
            return;
        }
        const payload = {
            userName: loginDetails.username,
            password: loginDetails.password,
        };
        callPostApiWithoutAuth(
            User_Login_Post,
            payload,
            (response) => {
                if (response.code === 200) {
                    const token = response.data.token;
                    const res = response.data;
                    const changePasswordFlag = response.data.user.ispasswordChanged;
                    if (changePasswordFlag === false) {
                        setShowChangePasswordModal(true);
                        setLoginToken(token);
                    } else {
                        navigate("/");
                        setDataInStore(BLAUTHTOKEN, token);
                    }
                }
            },
            (e) => {
                if (e.response.data.success === false) {
                    if (e.response.data.code === 400) {
                        toast.error("Please Enter valid credential");
                    }
                    // if (e.response.data.code === 401) {
                    //     toast.error(e.response.data.message);
                    // }
                    if (e.response.data.code === 422) {
                        toast.error(e.response.data.message);
                    }
                } else {
                    toast.error(e.message);
                }
            }
        );
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLoginDetails((prev) => ({ ...prev, [name]: value }));
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <>
            <div className="col-lg-4 col-md-6 col-6 text-center input-login position-relative">
                <form className="login-box w-100" onSubmit={handleLogin}>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <div className="input-group-text">
                                <img src="assets/img/login-user.png" alt="login-img" className="img-fluid" style={{ width: "22px" }} />
                            </div>
                        </div>
                        <input type="text" name="username" className="form-control" id="inlineFormInputGroup" placeholder="User Id" value={loginDetails.username} onChange={(e) => handleChange(e)} />
                    </div>
                    <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <div className="input-group-text">
                                <img src="assets/img/password.png" alt="login-img" className="img-fluid" style={{ width: "22px" }} />
                            </div>
                        </div>
                        <input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            className="form-control"
                            id="inlineFormInputGroup"
                            placeholder="Password"
                            value={loginDetails.password}
                            onChange={(e) => handleChange(e)}
                        />
                        <div className="input-group-append">
                            <div className="input-group-text" onClick={togglePasswordVisibility} style={{ cursor: "pointer" }}>
                                {showPassword ? (
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" style={{ fill: "#ffdc60", transform: "", msfilter: "" }}>
                                        <path d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 11c-2.206 0-4-1.794-4-4s1.794-4 4-4 4 1.794 4 4-1.794 4-4 4z" />
                                        <path d="M12 10c-1.084 0-2 .916-2 2s.916 2 2 2 2-.916 2-2-.916-2-2-2z" />
                                    </svg>
                                ) : (
                                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" style={{ fill: "#ffdc60", transform: "", msfilter: "" }}>
                                        <path d="M8.073 12.194 4.212 8.333c-1.52 1.657-2.096 3.317-2.106 3.351L2 12l.105.316C2.127 12.383 4.421 19 12.054 19c.929 0 1.775-.102 2.552-.273l-2.746-2.746a3.987 3.987 0 0 1-3.787-3.787zM12.054 5c-1.855 0-3.375.404-4.642.998L3.707 2.293 2.293 3.707l18 18 1.414-1.414-3.298-3.298c2.638-1.953 3.579-4.637 3.593-4.679l.105-.316-.105-.316C21.98 11.617 19.687 5 12.054 5zm1.906 7.546c.187-.677.028-1.439-.492-1.96s-1.283-.679-1.96-.492L10 8.586A3.955 3.955 0 0 1 12.054 8c2.206 0 4 1.794 4 4a3.94 3.94 0 0 1-.587 2.053l-1.507-1.507z" />
                                    </svg>
                                )}
                            </div>
                        </div>
                    </div>

                    <button type="submit" className="border-0 green-gradient-btn" onClick={handleLogin}>
                        <span className="green-inner-box" style={{ padding: "13px 86px" }}>
                            <span className="text-content">Log In</span>
                        </span>
                    </button>
                    <p className="m-0 mt-1 fs-12 text-grey mt-4">
                        By Login you accept you are 18+ and agree to our
                        <span className="text-yellow" onClick={() => setTermsModalOpen(true)}>
                            {" "}
                            T&amp;C
                        </span>{" "}
                        and
                        <span className="text-yellow" onClick={() => setPrivacyModalOpen(true)}>
                            {" "}
                            Privacy Policy
                        </span>
                    </p>
                    <div className="Login-casinoText">
                        <img src="assets/img/casinoText.png" alt="casinoText" className="w-75 casinoText" />
                    </div>
                </form>
                <div className="logo-opacity">
                    <div className="logo-shadow position-relative">
                        <img src="assets/img/shadow.png" alt="shadow" className="img-fluid round w-50" />
                        <div className="logo-position">
                            <img src="assets/img/logo.png" alt="logo" className="img-fluid" style={{ width: "30%" }} />
                        </div>
                    </div>
                </div>
            </div>
            <PrivacyPolicy showModal={termsModalOpen} toggleModel={() => setTermsModalOpen(false)} modalType="terms" />
            <PrivacyPolicy showModal={privacyModalOpen} toggleModel={() => setPrivacyModalOpen(false)} modalType="privacy" />
            <LoginPasswordUpdate loginToken={loginToken} showModal={showChangePasswordModal} toggleModel={() => {}} />
        </>
    );
};

export default Login;
