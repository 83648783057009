import React from "react";
import Modal from "react-modal";

Modal.setAppElement("#root");

export const ModalBox = ({ show, toggleModel, children, modalClass }) => {
    const overlyObject = { backgroundColor: "#00000096" };
    return (
        <Modal className={`${modalClass}`} isOpen={show} contentLabel="onRequestClose Example" onRequestClose={toggleModel} shouldCloseOnOverlayClick={true} style={{ overlay: overlyObject }}>
            {children}
        </Modal>
    );
};

export default ModalBox;
