// src/ProgressBar.js
import React, { useEffect, useState } from 'react';
import "../../src/App.css";


const ProgressBar = ({ value, duration }) => {
    const [percentage, setPercentage] = useState(0);

    useEffect(() => {
        let start = 0;
        const increment = value / (duration / 100);

        const interval = setInterval(() => {
            start += increment;
            if (start >= value) {
                start = value;
                clearInterval(interval);
            }
            setPercentage(start);
        }, 100);

        return () => clearInterval(interval);
    }, [value, duration]);

    return (
        <div className="progress-container">
            <div
                className="progress-bar"
                style={{ width: `${percentage}%` }}
            ></div>
            <div className="progress-text">{Math.round(percentage)}%</div>
        </div>
    );
};

export default ProgressBar;
