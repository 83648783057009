import { BrowserRouter, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import "./App.css";
import LoginUser from "./components/login/LoginUser";
import Dashboard from "./components/Dashboard";
import JoinTable from "./components/JoinTable";
import { BLAUTHTOKEN, getDataFromStore } from "./utils/CookiesUtils";
import { Children, useEffect, useState } from "react";
import LoginPasswordUpdate from "./components/login/LoginPasswordUpdate";
import { Toaster } from "react-hot-toast";
import ModalBox from "./components/common/Modal";
import SplashScreen from "./components/SplashScreen";

function RequireAuth() {
    const navigate = useNavigate();
    const token = getDataFromStore(BLAUTHTOKEN);

    // useEffect(() => {
    //   if (!token) {
    //     navigate("/login");
    //   }
    // }, [token]);

    useEffect(() => {
        if (token) {
            const wrapperDiv = document.getElementsByClassName("wrapper-main-box");
            if (wrapperDiv) {
                wrapperDiv[0].classList.add("wrapper-loginMain-box");
            }
        } else {
            const wrapperDiv = document.getElementsByClassName("wrapper-main-box");
            if (wrapperDiv && wrapperDiv[0].classList.contains("wrapper-loginMain-box")) {
                wrapperDiv[0].classList.remove("wrapper-loginMain-box");
            }
        }
    }, [token]);
    return token ? <Dashboard /> : <LoginUser />;
}

const toastDefaultOptionsStyle = {
    borderRadius: "5px",
    fontSize: "14px",
};

function App() {
    const [showSideMenuFlag, setShowSideMenuFlag] = useState(true);
    const [showOrientationModal, setShowOrientationModal] = useState(false);
    function receiveMessage(event) {
        if (event.data === "UnJoinTable") {
            setShowSideMenuFlag(true);
        } else if (event.data === "JoinTable") {
            setShowSideMenuFlag(false);
        }
    }
    window.addEventListener("message", receiveMessage, false);

    useEffect(() => {
        if (window.orientation === 0 || window.orientation === 180) {
            setShowOrientationModal(true);
        } else {
            setShowOrientationModal(false);
        }
    }, []);
    window.addEventListener("orientationchange", function (event) {
        if (window.orientation === 0 || window.orientation === 180) {
            setShowOrientationModal(true);
        } else {
            setShowOrientationModal(false);
        }
    });

    return (
        <>
            <ModalBox show={showOrientationModal} toggleModel={() => setShowOrientationModal(false)} modalClass={"sliderBlockModal"}>
                <div className="modal-dialog modal-dialog-centered">
                    <div className="orientationModal">
                        <div
                            className="text-center my-5"
                            style={{
                                textAlign: "center",
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <img className="orientationImg-div" src="https://royalityrummy.com/assets/images/orientationImg.png" alt="orientationImg" />
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    textAlign: "center",
                                }}
                            >
                                <h4 className="text-white" style={{ fontSize: "4vw", color: "#fff" }}>
                                    Please hold your device in portrait mode
                                </h4>
                            </div>
                        </div>
                    </div>
                </div>
            </ModalBox>
            <div className="wrapper-body">
                <div className={`wrapper-main-box wrapper-dashboard position-relative  ${showSideMenuFlag ? "" : "showTableView"}`}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/login" element={<LoginUser />} />
                            <Route path="/loginPasswordUpdate" element={<LoginPasswordUpdate />} />
                            <Route path="/" element={<RequireAuth />} />
                            <Route path="/joinTable" element={<JoinTable />} />
                            <Route path="/SplashScreen" element={<SplashScreen />} />
                        </Routes>
                    </BrowserRouter>
                </div>
            </div>
            <Toaster
                toastOptions={{
                    duration: 2000,
                    style: toastDefaultOptionsStyle,
                }}
            />
        </>
    );
}

export default App;
