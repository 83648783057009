import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { callGetApi } from "../api/ApiCaller";
import { Profile_GET } from "../api/ApiConst";
import toast, { Toaster } from "react-hot-toast";
import { convertAmount } from "../utils/CurrencyUtils";

const profileModal = {
    userName: "",
    fullName: "",
    mobile: "",
    exposerLimit: 0,
    accountBalance: 0,
    _id: "",
    profilePic: "",
};

const JoinTable = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [showSideMenuFlag, setShowSideMenuFlag] = useState(true);
    const [activeIFrame, setActiveIFrame] = useState(location.state.game);
    const [profile, setProfile] = useState(profileModal);

    useLayoutEffect(() => {
        getProfileDetails();
    }, []);

    const getProfileDetails = () => {
        callGetApi(
            Profile_GET,
            {},
            (response) => {
                if (response.code === 200) {
                    let res = response.data;
                    setProfile(res);
                } else {
                    toast.error(response.message);
                }
            },
            (e) => {
                toast.error(e.message);
            }
        );
    };

    const handleBack = () => {
        navigate("/");
    };

    function receiveMessage(event) {
        if (event.data === "UnJoinTable") {
            setShowSideMenuFlag(true);
        } else if (event.data === "JoinTable") {
            setShowSideMenuFlag(false);
        } else if (event.data === "UpdateBalance") {
            // getProfileDetails();
        }
    }

    window.addEventListener("message", receiveMessage, false);

    return (
        <>
            <>
                <div className="header header-outer position-relative">
                    <div className="header-inner">
                        <div className="d-flex">
                            <a className="me-4" onClick={handleBack}>
                                <img src="assets/img/back-arrow.png" alt="back-arrow" className="img-fluid" style={{ width: 96 }} />
                            </a>
                            <div className="px-3 py-1 d-flex align-items-center justify-content-between w-100">
                                <div className="table-amount">
                                    <div className=" d-flex align-items-center justify-content-between">
                                        <img src="assets/img/coin.png" alt="amount" className="img-fluid coin" />
                                        {/* <span className="fs-6">{convertAmount(profile?.accountBalance)}</span> */}
                                        <span className="fs-6">{profile?.accountBalance?.toFixed(2)}</span>
                                    </div>
                                </div>
                                {/* <a>
                                    <img src="assets/img/tressure.png" alt="Tressure" className="img-fluid" style={{ width: 41 }} />
                                </a> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid my-2 h-100 ">
                    <div className={`d-flex gap-3 joinGameListContent h-100 `}>
                        <div className="games-tab ">
                            <div className="border-before w-100 d-flex">
                                <ul className="nav nav-pills flex-column nav-pills" id="pills-tab" role="tablist">
                                    <div className="gameList">
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link text-primary fw-semibold position-relative ${activeIFrame === "teenPatti" ? "active" : ""}`}
                                                id="pills-teenpatti-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-teenpatti"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-teenpatti"
                                                aria-selected="true"
                                                onClick={() => setActiveIFrame("teenPatti")}
                                            >
                                                <img src="assets/img/teenpatti-tab.png" alt="teenpatti" className="img-fluid" />
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link text-primary fw-semibold position-relative ${activeIFrame === "andarBahar" ? "active" : ""}`}
                                                id="pills-andar-bahar-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-andar-bahar"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-andar-bahar"
                                                aria-selected="false"
                                                onClick={() => setActiveIFrame("andarBahar")}
                                            >
                                                <img src="assets/img/anda-bahar-tab.png" alt="teenpatti" className="img-fluid" />
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link text-primary fw-semibold position-relative ${activeIFrame === "updown" ? "active" : ""}`}
                                                id="pills-updown-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-updown"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-updown"
                                                aria-selected="false"
                                                onClick={() => setActiveIFrame("updown")}
                                            >
                                                <img src="assets/img/updown-tab.png" alt="teenpatti" className="img-fluid" />
                                            </button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button
                                                className={`nav-link text-primary fw-semibold position-relative ${activeIFrame === "dragon" ? "active" : ""}`}
                                                id="pills-dragon-tab"
                                                data-bs-toggle="pill"
                                                data-bs-target="#pills-dragon"
                                                type="button"
                                                role="tab"
                                                aria-controls="pills-dragon"
                                                aria-selected="false"
                                                onClick={() => setActiveIFrame("dragon")}
                                            >
                                                <img src="assets/img/dragon-tiger-tab.png" alt="teenpatti" className="img-fluid" />
                                            </button>
                                        </li>
                                    </div>
                                </ul>
                            </div>
                        </div>
                        <div className="games-content games-content-before">
                            <div className="tab-content w-100 p-custom z-3" id="pills-tabContent">
                                <div
                                    className={`tab-pane fade h-100 w-100  ${activeIFrame === "teenPatti" ? "show active" : ""}`}
                                    id="pills-teenpatti"
                                    role="tabpanel"
                                    aria-labelledby="pills-teenpatti-tab"
                                >
                                    {activeIFrame === "teenPatti" && (
                                        <iframe
                                            allow="microphone *"
                                            class="embed-responsive-item h-100 w-100 "
                                            src={`https://player-teenpatti.bhagyalaxmishivaexchange.com?at=${profile._id}&card_theme=${profile.cardTheme}`}
                                            allowfullscreen=""
                                        ></iframe>
                                    )}
                                </div>
                                <div
                                    className={`tab-pane fade h-100 w-100 ${activeIFrame === "andarBahar" ? "show active" : ""}`}
                                    id="pills-andar-bahar"
                                    role="tabpanel"
                                    aria-labelledby="pills-andar-bahar-tab"
                                >
                                    {activeIFrame === "andarBahar" && (
                                        <iframe
                                            allow="microphone *"
                                            class="embed-responsive-item h-100 w-100 "
                                            src={`https://player-andarbahar.bhagyalaxmishivaexchange.com?at=${profile._id}&card_theme=${profile.cardTheme}`}
                                            allowfullscreen=""
                                        ></iframe>
                                    )}
                                </div>
                                <div className={`tab-pane fade h-100 w-100 ${activeIFrame === "updown" ? "show active" : ""}`} id="pills-updown" role="tabpanel" aria-labelledby="pills-updown-tab">
                                    {activeIFrame === "updown" && (
                                        <iframe
                                            allow="microphone *"
                                            class="embed-responsive-item h-100 w-100 "
                                            src={`https://player-sevenupdown.bhagyalaxmishivaexchange.com?at=${profile._id}&card_theme=${profile.cardTheme}`}
                                            allowfullscreen=""
                                        ></iframe>
                                    )}
                                </div>
                                <div className={`tab-pane fade h-100 w-100 ${activeIFrame === "dragon" ? "show active" : ""}`} id="pills-dragon" role="tabpanel" aria-labelledby="pills-dragon-tab">
                                    {activeIFrame === "dragon" && (
                                        <iframe
                                            allow="microphone *"
                                            class="embed-responsive-item h-100 w-100 "
                                            src={`https://player-dragontiger.bhagyalaxmishivaexchange.com?at=${profile._id}&card_theme=${profile.cardTheme}`}
                                            allowfullscreen=""
                                        ></iframe>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
};

export default JoinTable;
